.container {
    display: flex;
    flex-direction: row;
    border-radius: 0.8rem;
    border: 0.2rem solid #F5835E;
    padding: 1.25rem 1.6rem;
    column-gap: 0.8rem;
    color: #FFF;
    cursor: pointer;
}

.countCircle {
    display: flex;
    width: 2.7rem;
    padding: 3.5px 8.5px 3.5px 9.5px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #F5835E;
}
