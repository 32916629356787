.mainContainer {
    display: flex;
    flex-direction: column;
}

.container {
    padding: 6.4em 10em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 900px) {
    .container {
        padding: 2em;
    }
}

.cartInfoLayout {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    max-width: 80%;
    justify-content: space-between;
    padding: 1.6em 2.4em;
    border-radius: 8px 8px 0 0;
    background: #F9F3EC;
    margin-top: 6.2em;
    cursor: pointer;
}

.cartInfoItem {
    display: flex;
    flex-direction: row;
    column-gap: 0.8em;
    justify-content: center;
    align-items: center;
}

.img {
    max-width: 24px;
    max-height: 24px;
}
