.footer {
background: #342C47;
  padding: 80px 137px 20px 137px;
}

.footer h3{
color: #FFF;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.footer p{
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
}


@media only screen and (max-width: 600px) {
.footer {

  padding: 80px 30px 20px 30px;
  text-align: center !important;
}
	
}


