.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    overflow: hidden;
    min-width: min-content;
    max-width: min-content;
}

@media(max-width: 900px) {
    .container {
        max-width: max-content;
    }
}


.img {
    min-width: 30em;
    min-height: 30em;
    max-width: 30em;
    max-width: 30em;
    flex-shrink: 0;
    border-radius: 8px 8px 0 0;
    background: #FAFAFA;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.mealInfo {
    padding: 2.4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6em;
}

.priceNButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttonContainer {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 10px;
    border-radius: 30px 0 8px 30px;
    background: #F5835E;

}

.button {
    cursor: pointer;
}
