.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6em;
}

.container:hover {
    background-color: aliceblue;

}

.img {
    border-radius: 8px;
    background: #D9D9D9;
    max-height: 14em;
    max-width: 14em;
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    object-fit: contain;
}
