.container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerCenter {
  width: 40%;
  padding: 4.8rem;
  align-self: center;
}
