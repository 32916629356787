.slider {
	background: #f9f3ec;
	padding: 50px 0;
}

.slider h2 {
	color: #342c47;
	font-family: Poppins;
	font-size: 60px;
	font-style: normal;
	font-weight: 700;
	line-height: 80px; /* 133.333% */
}
.slider h2 span {
	color: #f5835e;
}

.slider p {
	color: #6c6c6c;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.slider img {
	width: 567px;
	height: 545px;
}

@media only screen and (max-width: 600px) {
	.slider h2 {
		font-size: 30px;
		line-height: 40px;
	}
	.slider img {
		width: 266px;
		height: auto;
	}
}
