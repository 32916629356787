.ButHow{
    background: #F1E9DF;
  
}
.ButHow h3{
  color: #F5835E;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.ButHow h3 span{
    color: #342C47;
}

.ButHow p{
    color: #6C6C6C;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.ButHow .leftText{
    margin-left: 100px;
}
.ButHow img{
    position: relative;
    /* margin-right: -19px; */
    right: -24px;
}

@media only screen and (max-width: 600px) {

.ButHow .leftText{
    margin-left: 0px;
  padding: 86px 40px 10px 50px;
}

.ButHow h3 {
    font-size: 24px;
}
.ButHow img {
   
    right: -79px;
    width: 326px;
}
	
}