.partner {
  align-items: center;
  background-color: #f5835e;
  padding: 64px 240px;

}

.partner .do-you-share-our {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}


.btn-secondary {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  background-color: #342c47;
  text-align: center;
  width: fit-content;
  padding: 12.5px 16px;
  border-radius: 10px;
  border:unset;
}
@media only screen and (max-width: 600px) {
.partner {
 
  padding: 60px 40px;

}
}
