.together {
	background-color: #ffffff;
	padding: 80px 0;
}
.together h2 {
	color: #342c47;
	font-family: Poppins;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.together h2 span {
	color: #342c47;
}

.together p {
	color: #342c47;
}

.together .leftText {
	margin-left: 100px;
}

.together img {
	position: relative;
	bottom: -80px;
	right: -137px;
}

@media only screen and (max-width: 600px) {
	.together .leftText {
		margin-left: 60px;
		margin-right: 40px;
	}

	.together h2 {
		font-size: 28px;
	}
	.together img {
		right: -9px;
	}
}
