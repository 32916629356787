.container {
    display: flex;
    flex-direction: column;
    row-gap: 2.4em;
}

.cuisineContainer {
    display: grid;
    grid-template-columns: 1.2fr 3fr 20px;

}

.headMenuContainer {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin-left: 10em;
    cursor: pointer;
}

.arrow {
    padding-top: 5em;
    padding-left: 2em;
    cursor: pointer;
}

.arrow:hover {
    background-color: aliceblue;
}

.noteLayout {
    display: inline-flex;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 1px solid #F5835E;
}

@media(max-width: 900px) {
    .cuisineContainer {
        align-items: center;
    }

    .noteLayout {
        border-radius: 8px;
        border: 1px solid #F5835E;
    }

}
