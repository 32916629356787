.sustain {
	background-color: #f1e9df;
	padding: 80px 0;
}
.sustain h2 {
	color: #f5835e;
	font-family: Poppins;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.sustain h3 {
	color: #342c47;
	text-align: left;
	font-family: Poppins;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.sustain h2 span {
	color: #342c47;
}

.sustain p {
	color: #342c47;
}

.sustain .leftText {
	margin-left: 100px;
}

@media only screen and (max-width: 600px) {
	.sustain .leftText {
		margin-left: 0px;
		padding: 16px 40px 10px 50px;
	}
	.sustain h2 {
		font-size: 28px;
	}
	.sustain h3 {
		font-size: 24px;
	}
}
