.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.2em;
}

.horizontalList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 2rem;
    flex: 10 0 auto;
    min-width: 60vw;


}
