.exp{
    background-color: #F1E9DF;
    padding: 80px 0;
}
.exp h2{
    color: #F5835E;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.exp h2 span{
color: #342C47;
}

.exp p{
    color: #6C6C6C;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0 160px;
}

.exp h4{
color: #342C47;
text-align: center;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media only screen and (max-width: 600px) {
	.exp h2 {
		font-size: 28px;
		
	}

    .exp p {
    padding: 0 40px 0 50px;
    text-align:center;
}
	
}