body {
  margin: 0;
  /*   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  padding: 0;
  box-sizing: border-box;

  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, Inter;
}
.btn-primary {
  background-color: #f5835e;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;

  text-align: center;
  width: fit-content;
  padding: 12.5px 16px;
  border-radius: 10px;
  border: unset;
}

.btn-primary:hover {
  background-color: #342c47;
}

html {
  font-size: 62.5%;
}

.content-horizontal-margin {
  margin: 0 13.2em;
}

@media (max-width: 1500px) {
  .content-horizontal-margin {
    margin: 0 8em;
  }
}

@media (max-width: 1200px) {
  .content-horizontal-margin {
    margin: 0 4em;
  }
}

@media (max-width: 1000px) {
  .content-horizontal-margin {
    margin: 0 2em;
  }
}
