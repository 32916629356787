.infoContainer {
    margin-top: 6.4em;
    margin-left: 11em;
    margin-bottom: 9em;
    max-width: 52em;
    display: flex;
    flex-direction: column;
    row-gap: 4.8em;
}
@media(max-width: 900px) {
    .infoContainer {
        margin: 2.4em 1.6em;
        max-width: 100%;


    }
}


.infoText {
    flex-direction: row;
}

.socialContainer {
    flex-direction: column;
    row-gap: 1.6em;
}

.socialItems {
    display: flex;
    flex-direction: row;
    column-gap: 2.4em;
    justify-content: flex-start;
}

.cursorPointer {
    cursor: pointer;
}
