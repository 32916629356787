.container {
  background-color: #f5f5f580;
}

.mainContent {
  padding: 2.4rem 13.2rem 2.4rem 13.2rem;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  column-gap: 2.2rem;
}

.boxInfo {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
  align-items: center;
}
