.bg-body-tertiary {
    background-color: #F9F3EC !important;
}
.navbar {
    --bs-navbar-padding-y: 0.9rem;
}
.nav-link {
    color: #342C47;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 125% */
}
#navbarSupportedContent{
    text-align: right;
}