.headerContainer {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
  row-gap: 4rem;
  padding: 3.2rem;
  font-size: 1.6rem;
  color: #342c47;
  font-weight: 600;
  line-height: 20px;
  background-color: white;
}

.headerContainerBgColor {
  background-color: #f9f3ec;
}

.navSectionRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 2.4em;
}

.contackInfo {
  display: flex;
  column-gap: 1em;
}

.callImage {
  max-height: 2rem;
  max-width: 2rem;
  transform: translateY(5px);
}

.navActive {
  border-bottom: 3px solid #f5835e;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
}
.navStyle {
  cursor: pointer;
  text-decoration: none;
  padding: 10px 0;
  border-bottom: 3px solid transparent;
  color: #342c47;
}

.navStyle:hover {
  border-bottom: 3px solid #f5835e;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
}

.callImgContainer {
  padding: 1.2em;
  border-radius: 8px;
  background: #f5835e;
}

.img path {
  fill: white;
}

.navHolder {
  display: flex;
  column-gap: 4rem;
  justify-content: center;
  align-items: center;
}
