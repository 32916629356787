.expmenu {
	background-color: #fff;
	padding: 80px 0;
}
.expmenu h2 {
	color: #342c47;
	text-align: center;
	font-family: Poppins;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.expmenu h2 span {
	color: #f5835e;
}
.expmenu p {
	color: #6c6c6c;
	text-align: center;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.expmenu h4 {
	color: #342c47;
	text-align: center;
	font-family: Poppins;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.expmenu .box {
}

.expmenu .box p {
	color: #6c6c6c;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.expmenu .box img {
}

@media only screen and (max-width: 600px) {
	.expmenu h2 {
		font-size: 28px;
	}

	.expmenu {
		padding: 80px 50px;
	}
}
