.about{
    background-color: #fff;
    padding: 80px 0;
}
.about h2{
    color: #F5835E;
text-align: center;
font-family: Poppins;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.about h2 span{
color: #342C47;
}

.about p{
    color: #6C6C6C;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 0 160px;
}

@media only screen and (max-width: 600px) {
	.about h2 {
		font-size: 28px;
		
	}

    .about p {
    padding: 0 40px 0 50px;
    text-align: left;
}
	
}